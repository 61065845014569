import Api, { FetchMethodType } from './Api';

export interface StudentLoanAssistanceData {
  eligible: boolean;
  paymentStartDate?: string;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  currentPath?: PayoffData;
  recommendedPath?: PayoffData;

  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export interface PayoffData {
  monthsToDebtFree: number;
  monthlyPayment: number;
  totalPayment: number;
}

export interface StudentLoanCalculationParameters {
  forProfit?: boolean | null;
  nonProfitStartDate?: string;
  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export interface StudentLoanScheduleData {
  eventUri?: string;
  eventStartTime?: string;
  eventHost?: string;
  eventHostEmail?: string;
  eventName?: string;
  studentLoanData?: StudentLoanAssistanceData;
}

export interface StudentLoanApi {
  getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData>;
  setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<StudentLoanScheduleData>;
  updateStudentLoanAssistantData(
    applicationId: string,
    data: StudentLoanCalculationParameters,
  ): Promise<StudentLoanAssistanceData>;
}

export default class StudentLoanRestApi extends Api implements StudentLoanApi {
  async getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`);
  }

  async setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<StudentLoanScheduleData> {
    return this.fetch(`/studentLoan/schedule/${applicationId}/`, {
      method: FetchMethodType.POST,
      body: { eventUri },
    });
  }

  async updateStudentLoanAssistantData(
    applicationId: string,
    data: StudentLoanCalculationParameters,
  ): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: data,
    });
  }
}
